import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface sidebarShowState {
  show: boolean
}

const initialState: sidebarShowState = {
  show: true,
}

export const sidebarShowSlice = createSlice({
  name: 'sidebarShow',
  initialState,
  reducers: {
    toggleSidebarShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload
    },
  },
})

export const sidebarShowReducer = sidebarShowSlice.reducer
