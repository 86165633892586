import { createApi } from '@reduxjs/toolkit/query/react'
import { FieldsList } from 'shared/api'
import baseQueryWithAuth from 'shared/api/base'

export const fieldsListAPI = createApi({
  reducerPath: 'fieldsListAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['FieldsList'],
  endpoints: (build) => ({
    fetchAllFields: build.query<FieldsList, { limit?: number } | void>({
      query: ({ limit = undefined } = {}) => {
        let query = {}
        if (limit !== undefined) query = { limit }
        return { url: '/api/fields/', params: query }
      },
      providesTags: ['FieldsList'],
    }),
    createField: build.mutation<{ data: unknown }, { body: any }>({
      query: ({ body }) => {
        return { url: '/api/fields/', method: 'POST', body }
      },
      invalidatesTags: ['FieldsList'],
    }),
    deleteField: build.mutation<{ data: unknown }, { fieldId: string }>({
      query: ({ fieldId }) => {
        return { url: `/api/fields/${fieldId}`, method: 'DELETE' }
      },
      invalidatesTags: ['FieldsList'],
    }),
  }),
})
