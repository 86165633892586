import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IsFieldsState {
  isFields: boolean
}

const initialState: IsFieldsState = {
  isFields: true,
}

export const isFieldsSlice = createSlice({
  name: 'isFields',
  initialState,
  reducers: {
    changeIsFields: (state, action: PayloadAction<boolean>) => {
      state.isFields = action.payload
    },
  },
})

export const isFieldsReducer = isFieldsSlice.reducer
