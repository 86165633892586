import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from 'shared/api/base'
import {
  AlertConfigMeasuresResponse,
  FieldAlertConfig,
  FieldAlertConfigsResponse,
  FieldAlertsResponse,
} from '../types/alerts'

export const alertsAPI = createApi({
  reducerPath: 'alertsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: [
    'Field Alerts',
    'Field Alert Configs',
    'Alert Configs',
    'Alert Config',
    'Alert Measures',
  ],
  endpoints: (build) => ({
    getFieldAlerts: build.query<FieldAlertsResponse, { fieldId: string; params?: any }>({
      query: ({ fieldId, params }) => ({
        url: `/api/fields/${fieldId}/alerts/`,
        params,
      }),
      providesTags: ['Field Alerts'],
    }),
    getFieldAlertConfigs: build.query<FieldAlertConfigsResponse, { fieldId: string; params?: any }>(
      {
        query: ({ fieldId, params }) => ({
          url: `/api/fields/${fieldId}/alert-configs/`,
          params,
        }),
        providesTags: ['Field Alert Configs', 'Field Alerts', 'Alert Configs', 'Alert Config'],
      },
    ),
    createFieldAlertConfig: build.mutation<FieldAlertConfig, { fieldId: string; body: any }>({
      query: ({ fieldId, body }) => ({
        url: `/api/fields/${fieldId}/alert-configs/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Field Alert Configs', 'Field Alerts', 'Alert Configs', 'Alert Config'],
    }),
    getAlertConfigs: build.query<any, { params?: any }>({
      query: (params) => ({
        url: `/api/alert-configs/`,
        params,
      }),
      providesTags: ['Alert Configs'],
    }),
    getAlertConfig: build.query<any, { configId: string; params?: any }>({
      query: ({ configId, params }) => ({
        url: `/api/alert-configs/${configId}/`,
        params,
      }),
      providesTags: ['Alert Config'],
    }),
    updateAlertConfig: build.mutation<
      any,
      {
        configId: string
        body: {
          measure: string
          units: string
          lower_limit: string | number | null
          upper_limit: string | number | null
          freq_hours: string | number
        }
      }
    >({
      query: ({ configId, body }) => ({
        url: `/api/alert-configs/${configId}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Field Alert Configs', 'Field Alerts', 'Alert Configs', 'Alert Config'],
    }),
    deleteAlertConfig: build.mutation<any, { configId: string }>({
      query: ({ configId }) => ({
        url: `/api/alert-configs/${configId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Field Alert Configs', 'Field Alerts', 'Alert Configs', 'Alert Config'],
    }),
    getAlertMeasures: build.query<AlertConfigMeasuresResponse, void>({
      query: () => ({
        url: `/api/alert-measures/`,
      }),
      providesTags: ['Alert Measures'],
    }),
  }),
})
