import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from 'shared/api/base'

export const notesAPI = createApi({
  reducerPath: 'notesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Notes'],
  endpoints: (build) => ({
    getFieldNotes: build.query<{ data: unknown }, { fieldId: string }>({
      query: ({ fieldId }) => {
        return { url: `/api/fields/${fieldId}/notes/` }
      },
      providesTags: ['Notes'],
    }),
    getCompanyNotes: build.query<{ data: unknown }, void>({
      query: () => {
        return { url: `/api/notes/` }
      },
      providesTags: ['Notes'],
    }),
    createNote: build.mutation<{ data: unknown }, { fieldId: string; body: any }>({
      query: ({ fieldId, body }) => {
        return { url: `/api/fields/${fieldId}/notes/`, method: 'POST', body }
      },
      invalidatesTags: ['Notes'],
    }),
    // createImage: build.mutation<{ data: unknown }, { index: any; image: any; noteId: string }>({
    //   query: ({ index, image, noteId }) => {
    //     console.log(image)
    //     return {
    //       url: `/api/notes/${noteId}/files/`,
    //       method: 'POST',
    //       headers: { 'Content-Type': 'multipart/form-data' },
    //       body: { index, file: image.file },
    //     }
    //   },
    // }),
    deleteNote: build.mutation<{ data: unknown }, { noteId: string }>({
      query: ({ noteId }) => {
        return { url: `/api/notes/${noteId}/`, method: 'DELETE' }
      },
      invalidatesTags: ['Notes'],
    }),
  }),
})
