import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from 'shared/api/base'
import { FieldActivitiesPredict, FieldActivitiesPredictParams } from 'shared/api'

export const fieldActivitiesAPI = createApi({
  reducerPath: 'fieldActivitiesAPI',
  baseQuery: baseQueryWithAuth,
  endpoints: (build) => ({
    fetchFieldActivities: build.query<FieldActivitiesPredict, FieldActivitiesPredictParams>({
      query: ({ fieldId, lang, ...rest }) => ({
        url: `/api/fields/${fieldId}/activities/`,
        method: 'GET',
        params: {
          ...rest,
        },
      }),
      transformResponse: (response: { result: { days: [] } }) => response.result,
    }),
  }),
})
