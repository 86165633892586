import 'regenerator-runtime'
import axios from 'axios'
import { APIUrlsEnum } from 'shared/config'
import { analytics } from 'firebaseCfg'

const SaveToken = (tokenData: { access_token: string; refresh_token: string }) => {
  localStorage.removeItem('tokenData')
  localStorage.setItem('tokenData', JSON.stringify(tokenData))
}

export async function refreshToken() {
  const refresh_token = JSON.parse(localStorage.getItem('tokenData') as string).refresh_token
  return new Promise((resolve) => {
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/rest-auth/token/refresh/`, {
        refresh: refresh_token,
      })
      .then((res) => {
        if (res.status === 200) {
          const tokenData = { access_token: res.data.access, refresh_token: refresh_token }
          SaveToken(tokenData)
        }
        resolve(res)
      })
  })
}

const isNewUser = (access_token: string) => {
  axios
    .get(APIUrlsEnum.apiCompany, {
      headers: { authorization: `Bearer ${access_token}` },
    })
    .then((res) => {
      const companyName = res.data.name
      if (!companyName || companyName?.slice(0, 8) === 'Company:') {
        window.location.assign(`/company/create`)
      } else {
        axios
          .get(APIUrlsEnum.apiFields, {
            headers: { authorization: `Bearer ${access_token}` },
          })
          .then((res) => {
            const fieldsJson = res.data
            if (fieldsJson.count > 0) {
              if (localStorage.next) {
                const nextUrl = new URL(localStorage.next && localStorage.getItem('next'))
                if (nextUrl.pathname !== `/fields/create`) {
                  window.location.assign(localStorage.next && localStorage.getItem('next'))
                  localStorage.removeItem('next')
                } else window.location.assign(`/`)
              } else window.location.assign(`/`)
            } else window.location.assign(`/`)
          })
      }
    })
    .catch((err) => console.log(err))
}

interface SocialToken {
  googleToken?: string
  appleToken?: {
    authorization: {
      code: string
    }
  }
}

export async function getBackendTokens(social_token: SocialToken) {
  if (social_token.googleToken) {
    await axios
      .post(APIUrlsEnum.apiAuthGoogle, {
        access_token: social_token.googleToken,
      })
      .then((res) => {
        if (res.status === 200) {
          const tokenData = res.data
          delete tokenData.user
          SaveToken(tokenData)
          analytics?.logEvent('Web Logged in with google')
          isNewUser(tokenData.access_token)
        }
      })
  }
  if (social_token.appleToken) {
    await axios
      .post(APIUrlsEnum.apiAuthApple, {
        code: social_token.appleToken.authorization.code,
      })
      .then((res) => {
        if (res.status === 200) {
          const tokenData = res.data
          SaveToken(tokenData)
          analytics?.logEvent('Web Logged in with apple')
          isNewUser(tokenData.access_token)
        }
      })
  }
}
