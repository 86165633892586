import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from 'shared/api/base'
import {
  GetCurrentIrrigationStateResp,
  GetTargetIrrigationStateResp,
  SetIrrigationStateReq,
  SetIrrigationStateResp,
  GetIrrigationEventsListReq,
  GetIrrigationEventsListResp,
  GetFlowSeriesReq,
  GetFlowSeriesResp,
  PostFlowReadingsReq,
  PostFlowReadingsResp,
} from '../types/irrigation'
import { sha256 } from 'js-sha256'

export const irrigationAPI = createApi({
  reducerPath: 'irrigationAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Irrigation', 'FlowSeries', 'IrrigationState'],
  endpoints: (build) => ({
    getCurrentState: build.query<GetCurrentIrrigationStateResp, { deviceSerial: string }>({
      query: ({ deviceSerial }) => {
        const deviceId = sha256(deviceSerial)
        return { url: `/api/irrigation/ctrl/device/${deviceId}/state/` }
      },
      providesTags: ['Irrigation', 'IrrigationState'],
    }),
    getTargetState: build.query<GetTargetIrrigationStateResp, { deviceSerial: string }>({
      query: ({ deviceSerial }) => {
        const deviceId = sha256(deviceSerial)
        return { url: `/api/irrigation/ctrl/device/${deviceId}/target/` }
      },
      providesTags: ['Irrigation', 'IrrigationState'],
    }),
    setNewState: build.mutation<
      SetIrrigationStateResp,
      { body: SetIrrigationStateReq; deviceSerial: string }
    >({
      query: ({ deviceSerial, body }) => {
        const deviceId = sha256(deviceSerial)
        return { url: `/api/irrigation/ctrl/device/${deviceId}/cmd/open/`, method: 'POST', body }
      },
      invalidatesTags: ['Irrigation', 'IrrigationState'],
    }),
    getIrrigationEventsList: build.query<GetIrrigationEventsListResp, GetIrrigationEventsListReq>({
      query: ({ serial, field, state }) => {
        const query: GetIrrigationEventsListReq = {}
        if (serial !== undefined) query.serial = serial
        if (field !== undefined) query.field = field
        if (state !== undefined) query.state = state
        return { url: `/api/irrigation/events/`, params: query }
      },
      providesTags: ['Irrigation', 'FlowSeries'],
    }),
    getFlowSeries: build.query<GetFlowSeriesResp, GetFlowSeriesReq>({
      query: ({ deviceSerial, freq, start, end, limit, order }) => {
        interface Querry {
          order: string
          ipol: number
          v: number
          freq?: string
          start?: string
          end?: string
          limit?: number
        }
        const deviceId = sha256(deviceSerial)
        const ipol = 1
        const v = 2
        const query: Querry = { order: order ?? 'asc', ipol, v }
        if (limit !== undefined) query.limit = limit
        if (freq !== undefined) query.freq = freq
        if (start !== undefined) query.start = start
        if (end !== undefined) query.end = end
        return { url: `/api/device_id/${deviceId}/sensor/7/`, params: query }
      },
      providesTags: ['Irrigation', 'FlowSeries'],
    }),
    postFlowReadings: build.mutation<
      PostFlowReadingsResp,
      { body: PostFlowReadingsReq; deviceSerial: string }
    >({
      query: ({ deviceSerial, body }) => {
        const deviceId = sha256(deviceSerial)
        return { url: `/api/irrigation/device/${deviceId}/post-flow/`, method: 'POST', body }
      },
      invalidatesTags: ['Irrigation', 'FlowSeries'],
    }),
  }),
})
