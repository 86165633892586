import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ActiveCardState {
  activeCard: string | undefined
}

const initialState: ActiveCardState = {
  activeCard: undefined,
}

export const activeCardSlice = createSlice({
  name: 'activeCard',
  initialState,
  reducers: {
    selectActiveCard: (state, action: PayloadAction<string | undefined>) => {
      state.activeCard = action.payload
    },
  },
})

export const activeCardReducer = activeCardSlice.reducer
