import { APIUrlsEnum } from 'shared/config'
import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { refreshToken } from './auth'
import axios from 'axios'

const baseQuery = fetchBaseQuery({
  baseUrl: APIUrlsEnum.baseUrl,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('tokenData')
      ? JSON.parse(localStorage.getItem('tokenData') as string).access_token
      : null
    const lang = localStorage.lang ? localStorage.lang : 'en'
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('Accept-Language', lang)
    return headers
  },
})

const mutex = new Mutex()

const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const res = (await refreshToken()) as { status: number }
        if (res?.status === 200) {
          result = await baseQuery(args, api, extraOptions)
        } else {
          localStorage.setItem('next', window.location.href)
          window.location.href = '/login'
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

export default baseQueryWithAuth

export const axiosApiMultipartPostInstance = axios.create()

axiosApiMultipartPostInstance.interceptors.request.use(
  async (config: any) => {
    if (localStorage.tokenData) {
      const keys = JSON.parse(localStorage.getItem('tokenData') as string)
      config.headers = {
        Authorization: `Bearer ${keys.access_token}`,
        'Content-Type': 'multipart/form-data',
      }
    } else {
      localStorage.setItem('next', window.location.href)
      window.location.assign('/login')
    }
    return config
  },
  (error: any) => {
    Promise.reject(error)
  },
)

axiosApiMultipartPostInstance.interceptors.response.use(
  (response: any) => {
    return response
  },
  async function (error: any) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      refreshToken().then(() => {
        const newToken = JSON.parse(localStorage.getItem('tokenData') as string).access_token
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        originalRequest._retry = true
        return axiosApiMultipartPostInstance(originalRequest)
      })
    } else {
      Promise.reject(error)
    }
  },
)
