import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LangState {
  lang: 'ua' | 'en' | string
}

const initialState: LangState = {
  lang: localStorage.getItem('lang') ?? 'en',
}

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.lang = action.payload
    },
  },
})

export const langReducer = langSlice.reducer
