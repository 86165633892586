import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from 'shared/api/base'
import { FieldWeatherPredict, FieldWeatherPredictParams } from 'shared/api'

export const fieldWeatherAPI = createApi({
  reducerPath: 'fieldWeatherAPI',
  baseQuery: baseQueryWithAuth,
  endpoints: (build) => ({
    fetchFieldWeather: build.query<FieldWeatherPredict, FieldWeatherPredictParams>({
      query: ({ fieldId, ...rest }) => ({
        url: `/api/fields/${fieldId}/weather/`,
        method: 'GET',
        params: { ...rest },
      }),
      transformResponse: (response: { result: { days: [] } }) => response.result,
    }),
  }),
})
