import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from 'shared/api/base'
import {
  DevicesList,
  DevicesListParams,
  Device,
  DeviceInstance,
  DeviceInstanceUpdateParams,
  DeviceInstanceParams,
  ReadingsResponse,
  ReadingsRequest,
} from '../types'
import { sha256 } from 'js-sha256'

export interface LinkToField {
  fieldId: string
  body: {
    serial: string
  }
}

export const devicesAPI = createApi({
  reducerPath: 'devicesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Devices', 'FieldDevices', 'DeviceDetails'],
  endpoints: (build) => ({
    fetchCompanyDevices: build.query<DevicesList, DevicesListParams | void>({
      query: ({ limit = undefined } = {}) => {
        let query = {}
        if (limit !== undefined) query = { limit }
        return { url: `/api/devices/`, params: query }
      },
      providesTags: ['Devices'],
    }),
    fetchDeviceDetails: build.query<DeviceInstance, DeviceInstanceParams>({
      query: ({ deviceId }) => {
        return { url: `/api/devices/${deviceId}` }
      },
      providesTags: ['DeviceDetails'],
    }),
    checkIsExist: build.mutation<Device, { body: { serial: string } }>({
      query: ({ body }) => {
        return { url: `/api/devices/find/`, method: 'POST', body }
      },
    }),
    addCompanyDevice: build.mutation<DeviceInstance, { body: { serial: string } }>({
      query: ({ body }) => {
        return { url: `/api/devices/add/`, method: 'POST', body }
      },
      invalidatesTags: ['Devices'],
    }),
    updateCompanyDevice: build.mutation<{ data: unknown }, DeviceInstanceUpdateParams>({
      query: ({ deviceId, body }) => {
        return { url: `/api/devices/${deviceId}/`, method: 'PATCH', body }
      },
      invalidatesTags: ['DeviceDetails', 'Devices'],
    }),
    removeCompanyDevice: build.mutation<{ data: unknown }, { deviceId: string }>({
      query: ({ deviceId }) => {
        return { url: `/api/devices/${deviceId}/`, method: 'DELETE' }
      },
      invalidatesTags: ['Devices'],
    }),
    fetchFieldDevices: build.query<{ data: undefined }, { fieldId: string }>({
      query: ({ fieldId }) => {
        return { url: `/api/fields/${fieldId}/devices/` }
      },
      providesTags: ['FieldDevices'],
    }),
    linkFieldDevice: build.mutation<{ data: unknown }, LinkToField>({
      query: ({ fieldId, body }) => {
        return { url: `/api/fields/${fieldId}/devices/`, method: 'POST', body }
      },
      invalidatesTags: ['FieldDevices'],
    }),
    unlinkFieldDevice: build.mutation<{ data: unknown }, LinkToField>({
      query: ({ fieldId, body }) => {
        return { url: `/api/fields/${fieldId}/devices/`, method: 'PATCH', body }
      },
      invalidatesTags: ['FieldDevices'],
    }),
    fetchDeviceAirSensorLastVal: build.query<ReadingsResponse, ReadingsRequest>({
      query: ({ deviceSerial, unitsGroup }) => {
        const deviceId = sha256(deviceSerial)
        const isImperial = unitsGroup === 'us' ? 1 : 0
        const startDate = new Date()
        startDate.setDate(startDate.getDate() - 1)
        startDate.toISOString().slice(0, 10)
        const endDate = new Date().toISOString().slice(0, 10)
        return {
          url: `/api/device_id/${deviceId}/latest/?freq=h&units_imperial=${isImperial}&start=${endDate}&end=${endDate}&tz="None"&limit=48&order=asc&ipol=1&v=2`,
        }
      },
      providesTags: ['DeviceDetails'],
    }),
  }),
})
