import { createApi } from '@reduxjs/toolkit/query/react'
import { FieldInstance } from 'shared/api'
import baseQueryWithAuth from 'shared/api/base'

export const fieldInstanceAPI = createApi({
  reducerPath: 'fieldInstanceAPI',
  baseQuery: baseQueryWithAuth,
  endpoints: (build) => ({
    fetchFieldInstance: build.query<FieldInstance, { fieldId: string }>({
      query: ({ fieldId }) => ({
        url: `/api/fields/${fieldId}/`,
        method: 'GET',
      }),
    }),
  }),
})
