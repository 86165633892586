import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyD4QTlsSjjC8aEJFjQ-MUpZbk98CBEjEO8',
  authDomain: 'test-5f929.firebaseapp.com',
  databaseURL: 'https://test-5f929.firebaseio.com',
  projectId: 'test-5f929',
  storageBucket: 'test-5f929.appspot.com',
  messagingSenderId: '61941161264',
  appId: '1:61941161264:web:2d4be881f45dd612e9bcf5',
  measurementId: 'G-1881EP2YK0',
}
let analytics: firebase.analytics.Analytics | undefined
if (window.location.hostname === 'web.droppity.co') {
  firebase.initializeApp(firebaseConfig)
  analytics = firebase.analytics()
}

export { analytics }
