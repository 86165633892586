import React from 'react'
import { Route, Routes } from 'react-router-dom'

const DefaultLayout = React.lazy(() => import('pages/defaultLayout/Layout'))
// Pages
const Login = React.lazy(() => import('views/pages/login/Login'))
const CreateCompany = React.lazy(() => import('views/pages/company/CreateCompany'))

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/company/create" element={<CreateCompany />} />
        <Route path="*" element={<DefaultLayout />} />
      </Routes>
    </>
  )
}

export default Routing
