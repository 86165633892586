import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UnitsGroupState {
  unitsGroup: 'metric' | 'us' | string
}

const initialState: UnitsGroupState = {
  unitsGroup: localStorage.getItem('units') ?? 'us',
}

export const unitsGroupSlice = createSlice({
  name: 'unitsGroup',
  initialState,
  reducers: {
    changeUnitsGroup: (state, action: PayloadAction<'metric' | 'us' | string>) => {
      state.unitsGroup = action.payload
    },
  },
})

export const unitsGroupReducer = unitsGroupSlice.reducer
