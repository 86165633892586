import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from 'shared/api/base'

export interface UserCompany {
  created_at?: string
  created_by?: string
  id: string
  name: string
  type?: string
  users_number?: number
}

export const userCompanyAPI = createApi({
  reducerPath: 'userCompanyAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['userCompany'],
  endpoints: (build) => ({
    getCompany: build.query<UserCompany, void>({
      query: () => {
        return `/api/company/`
      },
      providesTags: ['userCompany'],
    }),
    createCompany: build.mutation<{ data: unknown }, { body: any }>({
      query: ({ body }) => {
        return { url: `/api/company/`, method: 'PUT', body }
      },
      invalidatesTags: ['userCompany'],
    }),
  }),
})
