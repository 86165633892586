import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagesListEnum } from 'shared/config'

export interface CurrPageState {
  currPage: `${PagesListEnum}`
}

const initialState: CurrPageState = {
  currPage: PagesListEnum.fieldsList,
}

export const currPageSlice = createSlice({
  name: 'currPage',
  initialState,
  reducers: {
    changeCurrPage: (state, action: PayloadAction<`${PagesListEnum}`>) => {
      state.currPage = action.payload
    },
  },
})

export const currPageReducer = currPageSlice.reducer
