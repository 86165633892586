import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

// eslint-disable-next-line react/display-name
export const withRouter = (component: () => React.ReactNode) => () => {
  const loading = (
    <div className="pt-5 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>{component()}</Suspense>
    </BrowserRouter>
  )
}
