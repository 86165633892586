import { LOCALES } from './locales'

export const messages = {
  [LOCALES.en]: {
    droppity: 'Droppity',
    home: 'Home',
    ok: 'OK',
    confirm: 'Confirm',
    update: 'Update',
    loading: 'Loading..',
    smthWetnWrong: 'Something went wrong, try again later or contact support',
    tryAgain: 'Try again',
    congratulations: 'Congratulations!',
    willBeUpdatedAt: 'Will be updated in ~{time} min',
    willStartInApprox:
      '{action} will start in ~{hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# min} other {# mins}}',
    willStartIn:
      '{action} will start in {hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# min} other {# mins}}',
    good: 'Optimal conditions',
    goodPopover: 'This is the best time for selected activity',
    moderate: 'Acceptable conditions',
    moderatePopover: 'Conditions are not optimal for selected activity',
    bad: 'Bad conditions',
    badPopover: 'Selected activity is not recommended during this period',
    uaLang: 'UA',
    enLang: 'EN',
    fieldActivitiesForecast: 'Field activities forecast',
    pleaseEnterFAO: 'Please Enter Transpiration Coefficient',
    pleaseEnterPrice: 'Please Enter Price',
    price: 'Price',
    pleaseEnterMoistureMeter: 'Please Enter Moisture, 1 meter',
    pleaseEnterMoisture3Feet: 'Please Enter Moisture, 3 feet',
    noNetworkConnection: 'No network connection!',
    companyNameIsRequired: 'Company name is required',
    invalidCompanyName: 'This name is not valid',
    fieldName: 'Field Name',
    fieldType: 'Field Type',
    irrigation: 'Irrigation',
    irrigationStopped: 'Irrigation stopped',
    logout: 'Logout',
    cancelAndLogout: 'Cancel and logout',
    about: 'About',
    termsOfService: 'Terms of Service',
    version: 'Version {version}',
    preorder: 'Preorder',
    demoMode: 'Demo Mode',
    howToUse: 'How To Use',
    copied: 'Copied',
    valueCopiedToClipboard: 'Value copied to Clipboard',
    settings: 'Settings',
    deleteDevice: 'DELETE DEVICE',
    deleteField: 'DELETE FIELD',
    deleteAccount: 'DELETE ACCOUNT',
    deleteNote: 'Delete note',
    deleteFieldOption: 'Delete field',
    deleteDeviceOption: 'Remove device',
    useMobileToCreateField: 'To create field using mobile install our application',
    updateFrequency: 'Update Frequency',
    updateFrequencyS: 'Update Frequency, s',
    changeUpdateFrequency: 'Change update frequency',
    lastLog: 'Last Log',
    inputVoltage: 'Input Voltage',
    batteryVoltage: 'Battery Voltage',
    batteryLevel: 'Battery level',
    chargerPower: 'Charger power',
    couldDecreaseBatteryLifeDramatically: 'Could decrease battery life dramatically',
    gsmSignal: 'GSM Signal',
    gsmSignalStrength: 'GSM Signal Strength',
    gsmSignalExcellent: 'Excellent',
    gsmSignalGood: 'Good',
    gsmSignalFair: 'Fair',
    gsmSignalPoor: 'Weak',
    gsmSignalBad: 'Bad',
    gsmSignalNo: 'No',
    operatorName: 'Operator Name',
    refreshInterval: 'Refresh Interval',
    buildVersion: 'Build Version',
    droppityHostBuildVersion: 'Droppity Host Build\nVersion',
    droppityHostMacVersion: 'Droppity Host Mac\nVersion',
    deviceHostBuildVersion: 'Device Host Build Version',
    ipAddress: 'IP Address',
    aquastopLimit: 'Aquastop Limit',
    deviceStatus: 'Device Status',
    wiFi: 'Wi-Fi',
    deviceId: 'Device ID',
    coordinates: 'Coordinates',
    forceUpdateCoordinates: 'Force update device coordinates',
    location: 'Location',
    forceUpdateLocation: 'Update location',
    deviceName: 'Device Name',
    editDeviceName: `Device name editing`,
    deviceLocationName: 'Device location',
    changeDeviceLocationName: 'Change device location',
    deviceDetails: 'Device Details',
    deviceInfo: 'Device Info',
    latestSensorData: 'Latest sensor data',
    online: 'online',
    offline: 'offline',
    orderNow: 'Order now',
    preorderNow: 'PREORDER NOW',
    needMoreDevices: 'Need more devices?',
    needDevice: 'Need device?',
    yourDevices: 'Your devices',
    tryDemoMode: 'TRY DEMO MODE',
    droppityCaresAboutYourPlants: 'Droppity cares\nabout your plants \uD83C\uDF31',
    droppityCaresAboutYourFields: 'Droppity cares\nabout your fields',
    droppityCaresAboutYourFieldsNoWrap: 'Droppity cares about your fields',
    orderDevice: 'Order Device',
    orderDroppityAgroDevice: 'Order Droppity Agro Device',
    addNewDevice: 'ADD NEW DEVICE',
    addNewNote: '+ Add new note',
    addName: 'ADD NAME',
    newField: 'New Field',
    whatWouldYouLikeToAdd: 'What would you like to add?',
    moreDetails: 'More details',
    unknown: 'unknown',
    lastTimeOnline: 'Last time online {time}',
    lastTimeOnlineWV: 'Last time online',
    flowerLeavesMayBeStressed: 'Flower leaves may be stressed',
    increasedRiskOfDevelopingPlantDiseases: 'Increased risk of developing plant diseases',
    needsWateringSoon: 'Needs watering soon',
    overwateringCouldAffectPlantHealth: 'Оverwatering could affect plant health',
    flowerMayBeStressed: 'Flower may be stressed',
    atmPressure: 'Atm. Pressure',
    airTemperature: 'Air temperature',
    airTemp: 'Air temp',
    airHumidity: 'Air humidity',
    airHum: 'Air hum',
    soilMoisture: 'Soil moisture',
    soilMoisture10cm: 'Soil moisture, 10cm',
    soilMoisture4inch: 'Soil moisture, 4″',
    soilMoisture20cm: 'Soil moisture 0-20cm',
    soilMoisture05ft: 'Soil moisture 0-0.5′',
    soilMoisture1m: 'Soil moisture, 1m',
    soilMoisture3ft4inch: 'Soil moisture, 3′4″',
    soilMoistureAt1m: 'Soil moisture at 1m',
    soilMoistureAt3ft: 'Soil moisture at 3ft',
    soilMoistureVol: 'Soil moisture vol.',
    soilMoistureVol10cm: 'Soil moisture vol., 10cm',
    soilMoistureVolAt10cm: 'Soil moisture volume at 10cm depth',
    soilMoistureVol4inch: 'Soil moisture vol., 4″',
    soilMoistureVolAt4inch: 'Soil moisture volume at 4 inches depth',
    soilMoistureVol20cm: 'Soil moisture vol. 0-20cm',
    soilMoistureVol05ft: 'Soil moisture vol. 0-0.5′',
    soilMoistureVol1m: 'Soil moisture vol., 1m',
    soilMoistureVol3ft4inch: 'Soil moisture vol., 3′4″',
    soilMoistureVolAt1m: 'Soil moisture volume avg at 1m',
    soilMoistureVolAt3ft: 'Soil moisture volume avg at 3ft',
    soilTemperature10cm: 'Soil temperature, 10cm',
    soilTemperature4inch: 'Soil temperature, 4″',
    soilTemperatureAt10cm: 'Soil temperature at 10cm depth',
    soilTemperatureAt4inch: 'Soil temperature at 4inch depth',
    soilTemperature1m: 'Soil temperature, 1m',
    soilTemperature3ft4inch: 'Soil temperature, 3′4″',
    soilMoistureInchLayer: `Soil moisture, {layer, plural, =1 {0-8″} =2 {8″-1′4″} =3 {1′4″-2′} =4 {2′-2′7″} =5 {2′7″-3′4″} other {#}}`,
    soilMoistureCMLayer:
      'Soil moisture, {layer, plural, =1 {0-20cm} =2 {20-40cm} =3 {40-60cm} =4 {60-80cm} =5 {80-100cm} other {#}}',
    soilTemperatureInchLayer: `Soil temperature, {layer, plural, =1 {0-8″} =2 {8″-1′4″} =3 {1′4″-2′} =4 {2′-2′7″} =5 {2′7″-3′4″} other {#}}`,
    soilTemperatureCMLayer:
      'Soil temperature, {layer, plural, =1 {0-20см} =2 {20-40см} =3 {40-60см} =4 {60-80см} =5 {80-100см} other {#}}',
    availableWater: 'Available water',
    crops: 'Crops',
    flowers: 'Flowers',
    greenhouse: 'Greenhouse',
    orchards: 'Orchards',
    vegetables: 'Vegetables',
    nonIrrigated: 'Non-irrigated',
    dripIrrigation: 'Drip irrigation',
    watteringFurrows: 'Wattering furrows',
    irrigationMachine: 'Irrigation machine',
    environmentInfo: 'Environment info',
    increasedEvapotranspirationToday: 'Increased evapotranspiration today.',
    lessThanUsualEvapotranspirationToday: 'Less than usual evapotranspiration today.',
    waterAsUsual: 'Water as usual.',
    moreLightMayBeRequiredToPromoteDenseFoliageAndFlowering:
      'More light may be required to promote dense foliage and flowering.',
    normal: 'Normal',
    cold: 'Cold',
    hot: 'Hot',
    dry: 'Dry',
    wet: 'Wet',
    fine: 'Fine',
    dewPoint: 'Dew point',
    waterVolumeNow: 'Soil moisture now in, {unit}: {value}',
    atmPressureInchHg: 'inHg',
    atmPressureMmHg: 'mmHg',
    temperatureAirC: 't air, °C',
    temperatureAirF: 't air, °F',
    temperature7cmC: 't soil 7cm, °C',
    temperature3InchF: 't soil 3″, °F',
    moistureSoilMm: 'moist soil, mm',
    moistureSoilInch: 'moist soil, inch',
    temperatureC: 'Temp. °C',
    temperatureF: 'Temp. °F',
    tempUnitF: '{value}°F',
    tempUnitC: '{value}°C',
    moistureUnitMmText: '{value} mm',
    moistureUnitInchText: '{value} in',
    moistureUnitInchSign: '{value} "',
    windMph: '{value} mph',
    windMs: '{value} m/s',
    precipitationInch: 'Precipitation, inch',
    precipitationMm: 'Precipitation, mm',
    moistureMm: 'Moist. mm',
    moistureInch: 'Moist. inch',
    moisturePct: 'Moist. %',
    moistureDynamics: 'Soil Moisture Dynamics',
    lastWatering: 'Last watering',
    sec: '{value} sec',
    min: '{value} min',
    minimumShort: 'min',
    maximumShort: 'max',
    minWithoutValue: 'min',
    ml: '{value} ml',
    minutes: '{value} minutes',
    hourWithVal: '{value} hour',
    hoursWithVal: '{value} hours',
    hoursAndMinutesWithVal:
      '{hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# min} other {# mins}}',
    startedHoursAndMinutesAgoWithVal:
      'Started {hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# min} other {# mins}} ago',
    startedAt: 'Started: {date}',
    finishedAt: 'Finished: {date}',
    finishedHoursAndMinutesAgoWithVal:
      'Finished {hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# min} other {# mins}} ago',
    irrigatedForHoursAndMinutesAgoWithVal:
      'Irrigated for {hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# min} other {# mins}}',
    irrigatingForHoursAndMinutesAgoWithVal:
      'Irrigating for {hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# min} other {# mins}}',
    readyForIrrigation: 'Ready for irrigation',
    daysWithVal: '{value} days',
    today: 'Today',
    tomorrow: 'Tomorrow',
    week: 'Week',
    holdForWatering: 'Hold for watering',
    temperatureWithValueC: 'Temperature {value}°C',
    airHumidityWithValue: 'Air humidity {value}%',
    airHumidityValue: '{value}%',
    zoneWithVal: 'Zone {value}',
    confirmSwitchIrrigationZoneWithVal: 'Confirm current irrigation zone switching to {zoneName}',
    switchIrrigationZoneError: `An error occurred, make sure everything is correct or contact support`,
    flowWithVal: 'Flow is {flow}{units}',
    waterFlowVolume: `Water volume, {units}`,
    flowSpeedWithVal: 'Flow is {flow} {units}',
    flowVolumeWithVal: `Total volume - {flow} {units}`,
    startWatering: 'Start watering',
    irrigationControls: 'Irrigation controls',
    valveControls: 'Valve controls',
    currentIrrigationState: 'Current irrigation state',
    startIrrigation: 'Start irrigation',
    irrigationCharts: 'Irrigation charts',
    watering: 'Watering...',
    stopWatering: 'Stop watering',
    weatherToday: 'Weather today',
    weather: 'Weather',
    sunset: 'SUNSET',
    sunrise: 'SUNRISE',
    daylightDuration: 'Daylight duration',
    id: 'ID',
    name: 'Name',
    or: 'Or',
    enterDeviceCode: 'ENTER DEVICE CODE',
    scanQrCodeOnDroppityDevice: 'Scan QR Code on the Droppity device',
    toScanQrCodeAllowDroppityToAccessCamera:
      'To scan the QR code, allow Droppity to access your camera',
    allow: 'ALLOW',
    enterDeviceName: 'Enter device name',
    deviceNameCannotBeEmpty: 'Device name cannot be empty',
    back: 'Back',
    skip: 'SKIP',
    next: 'NEXT',
    done: 'DONE',
    finish: 'FINISH',
    yourDeviceIsHere: 'Your device is here',
    toContinueSetupAllowDroppityToAccessLocation:
      'To continue device setup, allow Droppity to access your location',
    setupLocationLater: 'SETUP LOCATION LATER',
    enterCodeFromBackOfYourDevice: 'Enter the code from the back of your Droppity device',
    wrongDeviceIdCheckLabelOnDevice:
      'Wrong device ID, please check the label on your Droppity device',
    wrongDeviceId: 'Wrong device ID, {id}',
    checkLabelOnDevice: 'Please check the label on your Droppity device',
    harvestForecast: 'Harvest Forecast',
    lastUpdateAt: 'Last update at',
    updatedAt: 'Updated',
    ago: 'ago',
    updatedTimeAgo: 'Updated: {time} ago',
    winterWheat: 'Winter Wheat',
    corn: 'Corn',
    soy: 'Soy',
    sunflower: 'Sunflower',
    winterRapeseed: 'Winter Rapeseed',
    notEnoughDataForForecast: 'Not enough data for forecast',
    needsForFormationOfPotentialYields: 'Needs for the formation of potential yields',
    nitrogen: 'Nitrogen {mass}/{area}',
    phosphorus: 'Phosphorus {mass}/{area}',
    potassium: 'Potassium {mass}/{area}',
    harvestPotential: 'Yield potential ({unit})',
    strawAngGrain: 'straw + grain',
    recommendation: 'Recommendation',
    incomeFor: 'Income for 1 {area}. {currency}.',
    marketPrice: 'Market price ({currency}/{mass})',
    potentialGrainYield: 'Potential grain yield ({unit})',
    fao: 'Transpiration Coefficient',
    moistureSupplyInMeter: 'Moisture, 1 meter ({unit})',
    moistureSupplyIn3Feet: 'Moisture, 3 feet ({unit})',
    more: 'More',
    change: 'Change',
    changeLocation: 'CHANGE LOCATION',
    tabNow: 'Now',
    tabToday: 'Today',
    tabWeekly: 'Weekly',
    tabMonthly: 'Monthly',
    measureUnits: 'Measure Units',
    metric: 'Metric',
    imperial: 'Imperial',
    us: 'Imperial',
    metricWithExample: 'Metric (hectares)',
    imperialWithExample: 'Imperial (acres)',
    cancel: 'Cancel',
    signInToContinue: 'Sign In to Continue',
    continueWith: 'Continue with {store}',
    contactSupport: 'Contact Support',
    farmName: 'Farm Name',
    farmNameVal: '{fName} fields',
    pickLocation: 'Pick location on map',
    disease: 'Disease',
    addDisease: 'Add Disease',
    pests: 'Pest',
    addPests: 'Add Pest',
    fieldOperation: 'Field operation',
    addFieldOperation: 'Add Field operation',
    becomeAMember: 'Become a member',
    itLooksLikeYouDoNotHaveAnAccount: 'It looks like you do not have an account',
    continueBtn: 'Continue',
    continueWithGoogle: 'Continue with Google',
    continueWithApple: 'Continue with Apple',
    unit_kg: 'kg',
    unit_t: 't',
    unit_lb: 'lb',
    unit_m: 'm',
    unit_mm: 'mm',
    unit_cm: 'cm',
    unit_km: 'km',
    unit_in: '″',
    unit_inches: 'inches',
    unit_mi: 'mile',
    unit_mph: 'mph',
    unit_kmh: 'km/h',
    unit_ms: 'm/s',
    unit_m2: 'm²',
    unit_ha: 'ha',
    unit_ac: 'acre',
    unit_m3: 'm³',
    unit_bu: 'bu',
    unit_gal: 'gal',
    unit_degC: `°C`,
    unit_degF: `°F`,
    unit_pct: `%`,
    unit_galPerAcre: 'gal/acre',
    unit_m3PerHa: 'м³/ha',
    fieldAreaHa: '{value} ha',
    fieldAreaAcres: '{value} acres',
    deviceSerial: 'Device Serial',
    addNewField: 'ADD NEW FIELD',
    addNewFieldPlus: '+ ADD NEW FIELD',
    enterDeviceSerial: 'Enter serial number to add device',
    confirmDLinkDeviceSerial: 'Do you want to add device with serial number: {dLinkSerial}?',
    addDevice: 'ADD DEVICE',
    addNewDevice: 'ADD NEW DEVICE',
    addNewDevicePlus: '+ ADD NEW DEVICE',
    addNewFarmField: 'Add new farm field',
    optionalToInput: 'Optional to input',
    nextFieldRange: 'Next: Field range',
    extrasOnTheField: 'Extras on the field',
    addNewSensor: 'ADD NEW SENSOR',
    satelliteData: 'Satellite Data',
    myFields: '{my} Fields',
    windDirection: 'Wind direction',
    windSpeed: 'Wind speed',
    windGust: 'Wind gust',
    highAirTemperature: 'High Air Temperature',
    lowAirTemperature: 'Low Air Temperature',
    precipitation: 'Precipitation',
    windN: 'North',
    windNE: 'North-East',
    windE: 'East',
    windSE: 'South-East',
    windS: 'South',
    windSW: 'South-West',
    windW: 'West',
    windNW: 'North-West',
    droppityUser: 'Droppity User',
    locationPermissionDisabled: 'Location permission disabled',
    pleaseEnableLocationToCreateNewField: 'Please, enable location to create new field',
    areYouSureYouWantToDeleteAccount: 'Are you sure you want to delete account?',
    yes: 'Yes',
    no: 'No',
    fields: 'Fields',
    devices: 'Devices',
    fieldTypeIsRequired: 'Field type is required',
    irrigationIsRequired: 'Irrigation is required',
    exitMapMode: 'EXIT MAP MODE',
    selectedArea: 'Selected area',
    area: 'Area',
    areaIsTooBig: 'Area is too big',
    areaIsTooSmall: 'Area is too small',
    putTheFirstPoint: 'Put the first point',
    addTheNextPoint: 'Add the next point',
    touchTheFirstPointToCloseTheContour: 'Touch the first point to close the contour',
    areaIsCircled: 'Area is circled',
    canNotOpenUrl: 'Cannot open url',
    spraying: 'Spraying',
    gardening: 'Gardening',
    cultivation: 'Cultivation',
    harvesting: 'Harvesting',
    status: 'Status',
    days: 'Days',
    hours: 'Hours',
    details: 'Details',
    chartAirSensor: 'Air sensor ▼',
    chartLayerOne: 'Layer {unitGroup} ▼',
    chartLayerTwo: 'Layer {unitGroup} ▼',
    chartLayerThree: 'Layer {unitGroup} ▼',
    chartLayerFour: 'Layer {unitGroup} ▼',
    chartLayerFive: 'Layer {unitGroup} ▼',
    chartStackedSM: 'Stacked Soil Moisture ▼',
    chartMoistGainLoss: 'Soil Moist. Gain/Loss ▼',
    chartRelMoistGainLoss: 'Soil Moist. Relative Gain/Loss ▼',
    chartSatSoilMoistTemp: 'Satellite soil moist. and soil temp data ▼',
    chartSatAirHumTemp: 'Satellite air humidity and air temp data ▼',
    noFields: `It looks like you don't have any farm fields created yet. Please install the Droppity Agro mobile app to create your fields`,
    SatImgDate: 'SAT image date',
    NDVIInfo: 'NDVI Information',
    selectField: 'Select a field on the map to continue',
    notes: 'Notes',
    fieldsNotes: 'Fields Notes',
    notesCount: 'Notes ({notesCount})',
    showOnMap: 'Show on map',
    zeroNotes: `It looks like you don't have any field note created for your fields yet. Please create note for your field to see it in this list`,
    wrongDSerial: `Wrong device serial or this device is already added`,
    addDSerial: `If your Droppity Agro device is not connected to a field yet, please, enter device serial`,
    linkToField: `Link to field`,
    unlinkCurrDevice: `Unlink current device`,
    addAnotherDevice: `Link one more device to field`,
    newNote: 'New note',
    noteText: `Describe what's new on this field...`,
    uploadPhoto: 'Upload photo (limit 20)',
    save: 'Save',
    imagesLimit: 'Images limit 20 reached',
    observationDate: 'Observation Date:',
    fieldSelectedArea: 'Selected area: {area} {unitsEN}',
    onboardongFieldStepOne: 'Put the first point',
    onboardongFieldStepTwo: 'Add next points',
    onboardongFieldStepThree: `You can continue add points and edit bounds or save selected field`,
    onboardongFieldStepAreaBottom: 'Area is too small',
    onboardongFieldStepAreaTop: 'Area is too big',
    onboardongFieldStepDefault: 'Something broken!',
    value: 'value',
    logInitI2cDebugDeviceError: 'Peripheral device error',
    logInitI2cError: 'Peripheral device error',
    logInitSensorError: 'Sensor not connected.\nCheck wiring',
    logInitGsmError: 'Trouble with LTE connectivity',
    logInitGprsError: 'Trouble with LTE connectivity',
    logGetSensorDataError: 'Failed to get data from sensor',
    logGetDeviceDataError: 'Failed to get data from device',
    logGetDatetimeError: 'Problem with device date time',
    logFormSensorDataError: 'Problem with sensor data',
    logFormDeviceDataError: 'Problem with sensor data',
    logSendSensorDataError: 'Problem with sensor data',
    logSendDeviceDataError: 'Problem with device data',
    logGetDeviceInfoError: 'Failed to sync device data',
    logSensorCalibrationMoreThan100Error: 'Soil type calibration issue.\nContact support',
    logDevOtaStarted: 'Firmware update in progress',
    logDevOtaJobError: 'Failed to install firmware update',
    logDevOtaFilesystemError: 'Firmware update error.\nFilesystem error',
    logInternalBatCritical: 'Critically low battery',
    logDevGpsInitFail: 'Failed to get GPS coords',
    logDevGpsGetDataTimeout: 'Failed to get GPS',
    logDevRs485Fail: 'RS485 peripheral fail',
    logPeripheralDeviceError: 'Peripheral device error',
    irrigating: 'Irrigating',
    fieldAlerts: 'Field alerts',
    fieldAlertsNotConfigured: `Field alerts are not configured.`,
    pressAddAlerts: `Press 'Add Alerts' to start receiving alerts`,
    addAlerts: 'Add Alerts',
    noFieldAlertsWeek: `It's all ok. No field alerts for last week`,
    showAll: 'Show all',
    configure: 'Configure',
    yourAlertsWillBeSentTo: 'Your alerts will be sent to {to}',
    lowerThanExample: '{value}, {units}',
    biggerThanExample: '{value}, {units}',
    lowerThan: 'Lower than',
    greaterThan: 'Greater than',
    plusAddConfig: '+ Add config',
    close: 'Close',
    parameter: 'Parameter',
  },
  [LOCALES.ua]: {
    droppity: 'Droppity',
    home: 'Всі поля',
    ok: 'OK',
    confirm: 'Підтвердити',
    loading: 'Завантаження..',
    smthWetnWrong: `Щось пішло не так, спробуйте пізніше або зв'яжіться з підтримкою`,
    tryAgain: 'Спробувати ще раз',
    update: 'Оновити',
    congratulations: 'Вітаємо!',
    willBeUpdatedAt: 'Буде оновлено через ~{time} хв',
    willStartInApprox:
      '{action} розпочнеться через ~{hours, plural, =0 {} =1 {# година} =2 {# години} =3 {# години} =4 {# години} other {# годин}} {minutes, plural, =0 {} other {# хв}}',
    willStartIn:
      '{action} розпочнеться через {hours, plural, =0 {} =1 {# година} =2 {# години} =3 {# години} =4 {# години} other {# годин}} {minutes, plural, =0 {} other {# хв}}',
    good: 'Оптимально',
    goodPopover: 'Найкращі умови для вибраного виду діяльності',
    moderate: 'Прийнятно',
    moderatePopover: 'Не оптимальні умови для вибраного виду діяльності',
    bad: 'Погано',
    badPopover: 'Не рекомендується працювати у цей час',
    uaLang: 'UA',
    enLang: 'EN',
    fieldActivitiesForecast: 'Прогноз діяльності в полі',
    pleaseEnterFAO: 'Введіть Транспіраційний Коефіцієнт',
    pleaseEnterPrice: 'Введіть вартість',
    price: 'Вартість',
    pleaseEnterMoistureMeter: 'Введіть запас вологи в 1м шарі',
    pleaseEnterMoisture3Feet: 'Введіть запас вологи в 3x футовому шарі',
    noNetworkConnection: 'Відсутній доступ до Інтернету!',
    companyNameIsRequired: 'Укажіть назву компанії',
    invalidCompanyName: `Це ім'я не дозволено`,
    logout: 'Вийти',
    cancelAndLogout: 'Відмінити та вийти',
    farmName: "Ім'я ферми",
    farmNameVal: 'Поля {fName}',
    pickLocation: 'Виберіть місцезнаходження на карті',
    disease: 'Захворювання',
    addDisease: 'Додати захворювання',
    pests: 'Шкідники',
    addPests: 'Додати шкідників',
    fieldOperation: 'Операція на полі',
    addFieldOperation: 'Додати операцію на полі',
    signInToContinue: 'Увійдіть, щоб продовжити',
    continueWith: 'Продовжити з {store}',
    becomeAMember: 'Стати учасником',
    itLooksLikeYouDoNotHaveAnAccount: 'Схоже, у вас немає облікового запису',
    contactSupport: "Зв'язатися зі службою підтримки",
    continueBtn: 'Продовжити',
    continueWithGoogle: 'Продовжити з Google',
    continueWithApple: 'Продовжити з Apple',
    about: 'Про Програму',
    termsOfService: 'Умови Обслуговування',
    version: 'Версія {version}',
    preorder: 'Замовлення',
    demoMode: 'Демо Режим',
    howToUse: 'Як Користуватись',
    copied: 'Скопійовано',
    valueCopiedToClipboard: 'Скопійовано в буфер обміну',
    settings: 'Налаштування',
    deleteDevice: 'ВИДАЛИТИ ПРИСТРІЙ',
    deleteField: 'ВИДАЛИТИ ПОЛЕ',
    deleteNote: 'Видалити Замітку',
    deleteFieldOption: 'Видалити поле',
    deleteDeviceOption: 'Видалити пристрій',
    useMobileToCreateField:
      'Щоб створити поле користуючись мобільним пристроєм встановіть наш застосунок',
    updateFrequency: 'Частота Оновлення',
    updateFrequencyS: 'Частота Оновлення, с',
    changeUpdateFrequency: 'Змінити частоту оновлення',
    lastLog: 'Помилка Пристрою',
    inputVoltage: 'Вхідна Напруга',
    batteryVoltage: 'Напруга Акумулятора',
    batteryLevel: 'Рівень заряду батареї',
    chargerPower: 'Потужність зарядки',
    couldDecreaseBatteryLifeDramatically: 'Може сильно зменшити час життя батареї',
    gsmSignal: 'GSM Сигнал',
    gsmSignalStrength: 'Потужність Сигналу GSM',
    gsmSignalExcellent: 'Відмінний',
    gsmSignalGood: 'Хороший',
    gsmSignalFair: 'Нормальний',
    gsmSignalPoor: 'Кепський',
    gsmSignalBad: 'Дуже поганий',
    gsmSignalNo: 'Відсутній',
    operatorName: "Ім'я Оператора",
    refreshInterval: 'Інтервал Оновлення',
    buildVersion: 'Версія Збірки',
    droppityHostBuildVersion: 'Версія Збірки Хоста',
    droppityHostMacVersion: 'Версія Mac Хоста',
    deviceHostBuildVersion: 'Версія Збірки Хоста',
    ipAddress: 'IP адреса',
    aquastopLimit: 'Аквастоп ліміт',
    deviceStatus: 'Статус Пристрою',
    wiFi: 'Wi-Fi',
    deviceId: 'ID Пристрою',
    coordinates: 'Координати',
    forceUpdateCoordinates: 'Примусово оновити координати пристою',
    location: 'Місцезнаходження',
    forceUpdateLocation: 'Оновити місцезнаходження',
    deviceLocationName: 'Місцезнаходження пристрою',
    changeDeviceLocationName: 'Змінити місцезнаходження пристрою',
    deviceName: "Ім'я Пристрою",
    editDeviceName: `Зміна імені пристрою`,
    deviceDetails: 'Деталі пристрою',
    deviceInfo: 'Данні пристрою',
    latestSensorData: 'Останні показники сенсора',
    online: 'онлайн',
    offline: 'офлайн',
    orderNow: 'Замовити зараз',
    preorderNow: 'ЗАМОВИТИ ЗАРАЗ',
    needMoreDevices: 'Потрібно більше пристроїв?',
    needDevice: 'Потрібен пристрій?',
    yourDevices: 'Ваші пристрої',
    tryDemoMode: 'СПРОБУВАТИ ДЕМО-РЕЖИМ',
    droppityCaresAboutYourPlants: 'Droppity доглядає\nза вашими рослинами \uD83C\uDF31',
    addNewDevice: 'Додати Новий Пристрій',
    addNewNote: '+ Додати нову замітку',
    newField: 'Нове поле',
    moreDetails: 'Детальніше',
    unknown: 'невідомо',
    lastTimeOnline: 'Останній раз онлайн {time}',
    lastTimeOnlineWV: 'Останній раз онлайн',
    flowerLeavesMayBeStressed: 'Листочки рослинок можуть стресувать',
    increasedRiskOfDevelopingPlantDiseases: 'Підвищений ризик розвитку хвороб',
    needsWateringSoon: 'Потребує поливу',
    overwateringCouldAffectPlantHealth: 'Надмірний полив може вплинути на здоров’я рослин',
    flowerMayBeStressed: 'Рослинка може стресувать',
    atmPressure: 'Атмосферний тиск',
    airTemperature: 'Температура повітря',
    airTemp: 'Темп. повітря',
    airHumidity: 'Вологість повітря',
    airHum: 'Вол. повітря',
    soilMoisture: 'Вологість ґрунту',
    soilMoisture10cm: 'Вологість ґрунту, 10см',
    soilMoisture4inch: 'Вологість ґрунту, 4″',
    soilMoisture20cm: 'Вологість ґрунту 0-20',
    soilMoisture05ft: 'Вологість ґрунту 0.5′',
    soilMoisture1m: 'Вологість ґрунту, 1м',
    soilMoisture3ft4inch: 'Вологість ґрунту, 3′4″',
    soilMoistureAt1m: 'Вологість ґрунту на глибині 1м',
    soilMoistureAt3ft: 'Вологість ґрунту на глибині 3 фути',
    soilMoistureVol: `Вологість ґрунтуб об.`,
    soilMoistureVol10cm: `Вологість ґрунту об. 10см`,
    soilMoistureVol4inch: `Вологість ґрунту об. 4″`,
    soilMoistureVolAt10cm: `Об'ємна вологість ґрунту на глибині 10см`,
    soilMoistureVolAt4inch: `Об'ємна вологість ґрунту на глибині 4 фути`,
    soilMoistureVol20cm: `Вологість ґрунту об. 0-20`,
    soilMoistureVol05ft: `Вологість ґрунту об. 0.5′`,
    soilMoistureVol1m: `Вологість ґрунту об. 1м`,
    soilMoistureVol3ft4inch: `Вологість ґрунту об. 3′4″`,
    soilMoistureVolAt1m: `Об'ємна вологість ґрунту усередн. на глибині 1м`,
    soilMoistureVolAt3ft: `Об'ємна вологість ґрунту усередн. на глибині 3 фути`,
    soilTemperature10cm: 'Температура ґрунту, 10см',
    soilTemperature4inch: 'Температура ґрунту, 4″',
    soilTemperatureAt10cm: 'Температура ґрунту на глибині 10см',
    soilTemperatureAt4inch: 'Температура ґрунту на глибині 4″',
    soilTemperature1m: 'Температура ґрунту, 1м',
    soilTemperature3ft4inch: 'Температура ґрунту, 3′4″',
    soilMoistureInchLayer: `Вологість ґрунту, {layer, plural, =1 {0-8″} =2 {8″-1′4″} =3 {1′4″-2′} =4 {2′-2′7″} =5 {2′7″-3′4″} other {#}}`,
    soilMoistureCMLayer:
      'Вологість ґрунту, {layer, plural, =1 {0-20см} =2 {20-40см} =3 {40-60см} =4 {60-80см} =5 {80-100см} other {#}}',
    soilTemperatureInchLayer: `Температура ґрунту, {layer, plural, =1 {0-8″} =2 {8″-1′4″} =3 {1′4″-2′} =4 {2′-2′7″} =5 {2′7″-3′4″} other {#}}`,
    soilTemperatureCMLayer:
      'Температура ґрунту, {layer, plural, =1 {0-20см} =2 {20-40см} =3 {40-60см} =4 {60-80см} =5 {80-100см} other {#}}',
    availableWater: 'Інформація по загальній волозі',
    crops: 'Культури',
    flowers: 'Квіти',
    greenhouse: 'Теплиця',
    orchards: 'Фруктові сади',
    vegetables: 'Овочі',
    nonIrrigated: 'Незрошуваний',
    dripIrrigation: 'Крапельний полив',
    watteringFurrows: 'Поливні борозни',
    irrigationMachine: 'Поливна машина',
    environmentInfo: 'Інформація по середовищу',
    increasedEvapotranspirationToday: 'Випаровування посилене сьогодні.',
    lessThanUsualEvapotranspirationToday: 'Bипаровування сьогодні менше, ніж зазвичай.',
    waterAsUsual: 'Поливайте як зазвичай.',
    moreLightMayBeRequiredToPromoteDenseFoliageAndFlowering:
      'Для сприяння росту та цвітінню може знадобитися більше світла.',
    normal: 'Нормально',
    cold: 'Холодно',
    hot: 'Жарко',
    dry: 'Сухо',
    wet: 'Волого',
    fine: 'Прекрасно',
    dewPoint: 'Точка роси',
    waterVolumeNow: 'Вологість ґрунту зараз, {unit}: {value}',
    atmPressureInchHg: 'дюйм рт. ст.',
    atmPressureMmHg: 'мм рт. ст',
    temperatureAirC: 't пов, °C',
    temperatureAirF: 't пов, °F',
    temperature7cmC: 't ґрунт 7см, °C',
    temperature3InchF: 't ґрунт 3″, °F',
    moistureSoilMm: 'вол. ґрунт, мм',
    moistureSoilInch: 'вол. ґрунт, дюйм',
    temperatureC: 'Темп. °C',
    temperatureF: 'Темп. °F',
    tempUnitF: '{value}°F',
    tempUnitC: '{value}°C',
    moistureUnitMmText: '{value}мм',
    moistureUnitInchText: '{value, plural, one {# дюйм} other {# дюйм(а/ів)}}',
    moistureUnitInchSign: '{value} "',
    windMph: '{value} миль/г',
    windMs: '{value} м/с',
    moistureMm: 'Волог. mm',
    moistureInch: 'Волог. дюйм',
    moisturePct: 'Волог. %',
    moistureDynamics: 'Динаміка вологості ґрунту',
    lastWatering: 'Останній полив',
    sec: '{value} сек',
    min: '{value} хв',
    minimumShort: 'мін.',
    maximumShort: 'макс.',
    minWithoutValue: 'хв',
    ml: '{value} мл',
    minutes: '{value} хвилин',
    hourWithVal: '{value} година',
    hoursWithVal: '{value} годин',
    hoursAndMinutesWithVal:
      '{hours, plural, =0 {} =1 {# година} =2 {# години} =3 {# години} =4 {# години} other {# годин}} {minutes, plural, =0 {} other {# хв}}',
    startedHoursAndMinutesAgoWithVal:
      'Розпочато {hours, plural, =0 {} =1 {# година} =2 {# години} =3 {# години} =4 {# години} other {# годин}} {minutes, plural, =0 {} other {# хв}} тому',
    startedAt: 'Розпочато: {date}',
    finishedAt: 'Припинено: {date}',
    finishedHoursAndMinutesAgoWithVal:
      'Завершено {hours, plural, =0 {} =1 {# година} =2 {# години} =3 {# години} =4 {# години} other {# годин}} {minutes, plural, =0 {} other {# хв}} тому',
    irrigatingForHoursAndMinutesAgoWithVal:
      'Зрошується протягом {hours, plural, =0 {} =1 {# години} =2 {# годин} =3 {# годин} =4 {# годин} other {# годин}} {minutes, plural, =0 {} other {# хв}}',
    irrigatedForHoursAndMinutesAgoWithVal:
      'Зрошували протягом {hours, plural, =0 {} =1 {# години} =2 {# годин} =3 {# годин} =4 {# годин} other {# годин}} {minutes, plural, =0 {} other {# хв}}',
    readyForIrrigation: 'Готово до зрошення',
    daysWithVal: '{value} днів',
    today: 'Сьогодні',
    tomorrow: 'Завтра',
    week: 'Тиждень',
    holdForWatering: 'Утримуйте для поливу',
    temperatureWithValueC: 'Температура {value}°C',
    airHumidityWithValue: 'Вологість повітря {value}%',
    airHumidityValue: '{value}%',
    zoneWithVal: 'Зона {value}',
    confirmSwitchIrrigationZoneWithVal: 'Підтвердіть зміну поточної зони поливу на {zoneName}',
    switchIrrigationZoneError: `Виникла помилка, впевніться, що все правильно або зв'яжіться з підтримкою`,
    flowWithVal: 'Потік - {flow}{units}',
    waterFlowVolume: `Об'єм води, {units}`,
    flowSpeedWithVal: 'Потік - {flow} {units}',
    flowVolumeWithVal: `Загальний об'єм - {flow} {units}`,
    startWatering: 'Розпочати полив',
    currentIrrigationState: 'Поточний стан поливу',
    irrigationControls: 'Керування поливом',
    valveControls: 'Керування клапанами',
    startIrrigation: 'Розпочати полив',
    irrigationCharts: 'Графіки зрошення',
    watering: 'Полив...',
    stopWatering: 'Зупинити полив',
    weatherToday: 'Погода сьогодні',
    daylightDuration: 'Світловий день',
    id: 'ID',
    name: "Ім'я",
    or: 'Або',
    enterDeviceCode: 'ВВЕСТИ КОД ПРИСТРОЮ',
    scanQrCodeOnDroppityDevice: 'Відскануйте QR Код на вашому пристрої',
    toScanQrCodeAllowDroppityToAccessCamera:
      'Для того шоб відсканувати QR код, дозвольте Droppity використовувати камеру',
    allow: 'Дозволити',
    enterDeviceName: 'Введіть ім`я пристрою',
    deviceNameCannotBeEmpty: 'Ім`я пристрою не може бути пустим',
    back: 'Повернутись',
    skip: 'ПРОПУСТИТИ',
    next: 'ВПЕРЕД',
    done: 'ГОТОВО',
    finish: 'ЗАКІНЧИТИ',
    yourDeviceIsHere: 'Ваш пристрій знаходиться тут',
    toContinueSetupAllowDroppityToAccessLocation:
      'Щоб продовжити налаштування пристрою, дозвольте Droppity отримувати доступ до вашого місцезнаходження',
    setupLocationLater: 'НАЛАШТУВАТИ МІСЦЕЗНАХОДЖЕННЯ ПОТІМ',
    enterCodeFromBackOfYourDevice: 'Введіть код, що знаходиться на пристрою',
    wrongDeviceIdCheckLabelOnDevice:
      'Невірний ID пристрою, будь ласка перевірте правильність даних',
    wrongDeviceId: 'Невірний ID пристрою, {id}',
    checkLabelOnDevice: 'Будь ласка перевірте правильність даних',
    harvestForecast: 'Прогноз Врожаю',
    lastUpdateAt: 'Останнє оновлення в',
    updatedAt: 'Оновлено',
    ago: 'тому',
    updatedTimeAgo: 'Оновлено: {time} тому',
    winterWheat: 'Озима Пшениця',
    corn: 'Кукурудза',
    soy: 'Соя',
    sunflower: 'Соняшник',
    winterRapeseed: 'Озимий Ріпак',
    notEnoughDataForForecast: 'Недостатньо данних для прогнозу',
    needsForFormationOfPotentialYields: 'Потреби для формування потенційної врожайності',
    nitrogen: 'Азот діючої речовини {mass}/{area}',
    phosphorus: 'Фосфор діючої речовини {mass}/{area}',
    potassium: 'Калій діючої речовини {mass}/{area}',
    harvestPotential: 'Потенціал врожаю ({unit})',
    strawAngGrain: 'солома + зерно',
    recommendation: 'Рекомендація',
    incomeFor: 'Дохід з 1 {area}. {currency}.',
    marketPrice: 'Вартість продукції ({currency}/{mass})',
    potentialGrainYield: 'Потен. врожай зерна ({unit})',
    fao: 'Транспіраційний Коефіцієнт',
    moistureSupplyInMeter: 'Зап. вологи в метр. шарі ({unit})',
    moistureSupplyIn3Feet: 'Зап. вологи в 3 футах ({unit})',
    more: 'Детальніше',
    change: 'Змінити',
    changeLocation: 'ЗМІНИТИ ЛОКАЦІЮ',
    tabNow: 'Зараз',
    tabToday: 'Сьогодні',
    tabWeekly: 'Тиждень',
    tabMonthly: 'Місяць',
    measureUnits: 'Одиниці Міри',
    metric: 'Метричні',
    imperial: 'Британські',
    us: 'Британські',
    metricWithExample: 'Метричні (гектар)',
    imperialWithExample: 'Британські (акр)',
    cancel: 'Скасувати',
    unit_kg: 'кг',
    unit_t: 'т',
    unit_lb: 'фунт',
    unit_m: 'м',
    unit_mm: 'мм',
    unit_cm: 'см',
    unit_km: 'км',
    unit_in: '″',
    unit_inches: 'фути',
    unit_mi: 'миля',
    unit_m2: 'м²',
    unit_ha: 'га',
    unit_ac: 'акр',
    unit_m3: 'м³',
    unit_bu: 'бушель',
    unit_gal: 'галон',
    unit_mph: 'миль/г',
    unit_kmh: 'км/год',
    unit_ms: 'м/c',
    unit_degC: `°C`,
    unit_degF: `°F`,
    unit_pct: `%`,
    unit_galPerAcre: 'галон/акр',
    unit_m3PerHa: 'м³/га',
    fieldAreaHa: '{value} га',
    fieldAreaAcres: '{value} акр.',
    droppityCaresAboutYourFields: 'Droppity піклується\nпро ваші поля',
    droppityCaresAboutYourFieldsNoWrap: 'Droppity піклується про ваші поля',
    orderDevice: 'Замовити Пристрій',
    orderDroppityAgroDevice: 'Замовити Droppity Agro пристрій',
    whatWouldYouLikeToAdd: 'Що б ви хотіли додати?',
    precipitationInch: 'Кількість опадів, дюйм',
    precipitationMm: 'Кількість опадів, мм',
    weather: 'Погода',
    sunset: 'ЗАХІД',
    sunrise: 'СХІД',
    deviceSerial: 'Серійний номер пристрою',
    addNewField: 'ДОДАТИ НОВЕ ПОЛЕ',
    addNewFieldPlus: '+ ДОДАТИ НОВЕ ПОЛЕ',
    addName: `ДОДАТИ ІМ'Я`,
    enterDeviceSerial: 'Введіть серійний номер, щоб додати пристрій',
    confirmDLinkDeviceSerial: 'Ви хочете додати пристрій з серійним номером: {dLinkSerial}?',
    addDevice: 'ДОДАТИ ПРИСТРІЙ',
    addNewDevice: 'ДОДАТИ НОВИЙ ПРИСТРІЙ',
    addNewDevicePlus: '+ ДОДАТИ НОВИЙ ПРИСТРІЙ',
    addNewSensor: 'ДОДАТИ НОВИЙ СЕНСОР',
    satelliteData: 'Супутникові Дані',
    myFields: 'Поля {my}',
    windDirection: 'Напрямок вітру',
    windSpeed: 'Швидкість вітру',
    windGust: 'Пориви вітру',
    highAirTemperature: 'Найвища температура повітря',
    lowAirTemperature: 'Найнижча температура повітря',
    precipitation: 'Опади',
    windN: 'Північний',
    windNE: 'Північно-Східний',
    windE: 'Східний',
    windSE: 'Південно-Східний',
    windS: 'Південний',
    windSW: 'Південно-Західний',
    windW: 'Західний',
    windNW: 'Північно-Західний',
    droppityUser: 'Користувач Droppity',
    locationPermissionDisabled: 'Дозвіл на місцезнаходження вимкнено',
    pleaseEnableLocationToCreateNewField:
      'Будь ласка, увімкніть геолокацію, щоб створити нове поле',
    areYouSureYouWantToDeleteAccount: 'Ви дійсно бажаєте видалити аккаунт?',
    yes: 'Так',
    no: 'Ні',
    fieldName: "Ім'я поля",
    fieldType: 'Тип поля',
    irrigation: 'Зрошення',
    irrigationStopped: 'Зрошення зупинено',
    deleteAccount: 'ВИДАЛИТИ АККАУНТ',
    addNewFarmField: 'Додати нове поле ферми',
    optionalToInput: 'Необов’язковий для введення',
    nextFieldRange: 'Далі: Діапазон поля',
    extrasOnTheField: 'Додаткові дані поля',
    fields: 'Поля',
    devices: 'Пристрої',
    fieldTypeIsRequired: 'Тип поля обов’язковий',
    irrigationIsRequired: 'Необхідно ввести тип поливу',
    exitMapMode: 'ВИЙТИ З МАПИ',
    selectedArea: 'Загальна площа',
    area: 'Площа',
    areaIsTooBig: 'Площа занадто велика',
    areaIsTooSmall: 'Площа занадто мала',
    putTheFirstPoint: 'Додайте першу точку',
    addTheNextPoint: 'Додайте наступну точку',
    touchTheFirstPointToCloseTheContour: 'Торкніться першої точки, щоб закрити контур',
    areaIsCircled: 'Контур закрито',
    canNotOpenUrl: 'Неможливо відкрити посилання',
    spraying: 'Оприскування',
    gardening: 'Садівництво',
    cultivation: 'Культивація',
    harvesting: 'Збір врожаю',
    status: 'Статус',
    days: 'Дні',
    hours: 'Години',
    details: 'Деталі',
    chartAirSensor: 'Повітряний сенсор ▼',
    chartLayerOne: `Шар {unitGroup} ▼`,
    chartLayerTwo: 'Шар {unitGroup} ▼',
    chartLayerThree: 'Шар {unitGroup} ▼',
    chartLayerFour: 'Шар {unitGroup} ▼',
    chartLayerFive: 'Шар {unitGroup} ▼',
    chartStackedSM: 'Пошарова вологість ґрунту ▼',
    chartMoistGainLoss: 'Вологість ґрунту Додалось/Висохло ▼',
    chartRelMoistGainLoss: 'Відносна вологість ґрунту Додалось/Висохло ▼',
    chartSatSoilMoistTemp: 'Супутникові дані к-сті вологості та температури ґрунту ▼',
    chartSatAirHumTemp: 'Супутникові дані вологості та температури повітря ▼',
    noFields: `Здається у вас ще немає жодного створенного поля. Будь ласка, встановіть мобільний додаток Droppity Agro та скористайтесь ним для створення поля`,
    SatImgDate: 'Дата Суп. знімку',
    NDVIInfo: 'Інформація про NDVI',
    selectField: 'Виберіть поле на карті, щоб продовжити',
    notes: 'Замітки',
    fieldsNotes: 'Польові замітки',
    notesCount: 'Замітки ({notesCount})',
    showOnMap: 'Показати на карті',
    zeroNotes: `Здається у вас ще немає жодної створенної замітки до жодного вашого поля. Будь ласка, створіть замітку для вашого поля, що побачити її у цьому списку`,
    wrongDSerial: `Не вірний серійний номер пристрою або цей пристрій уже додано`,
    addDSerial: `Якщо ваш пристрій Droppity Agro ще не прив'язаний до поля, будь ласка, введіть серійний номер пристрою`,
    linkToField: `Прив'язати до поля`,
    unlinkCurrDevice: `Відв'язати поточний пристрій`,
    addAnotherDevice: `Прив'яжіть ще один девайс до поля`,
    newNote: 'Нова замітка',
    noteText: 'Опишіть, що нового на цьому полі...',
    uploadPhoto: 'Завантажити фото (ліміт 20)',
    save: 'Зберегти',
    imagesLimit: 'Досягнуто ліміт 20 фото',
    observationDate: 'Дата Спостереження:',
    fieldSelectedArea: 'Виділена площа: {area} {unitsUA}',
    onboardongFieldStepOne: 'Поставте першу точку',
    onboardongFieldStepTwo: 'Додайте наступну точку',
    onboardongFieldStepThree: `Ви можете продовжити додавати точки і редагувати межі або зберегти вибране поле`,
    onboardongFieldStepAreaBottom: 'Площа надто маленька',
    onboardongFieldStepAreaTop: 'Площа надто велика',
    onboardongFieldStepDefault: 'Щось зламалося',
    value: 'Значення',
    logInitI2cDebugDeviceError: 'Проблема підключення сенсору',
    logInitI2cError: 'Проблема підключення сенсору',
    logInitSensorError: 'Перевірте підключення сенсорів',
    logInitGsmError: "Проблема LTE зв'язку",
    logInitGprsError: "Проблема LTE зв'язку",
    logGetSensorDataError: 'Проблема з отриманням данних із сенсора',
    logGetDeviceDataError: 'Проблема з отриманням данних із пристрою',
    logGetDatetimeError: 'Проблема з визначенням дати/часу пристрою',
    logFormSensorDataError: 'Проблема з обробкою данних сенсора',
    logFormDeviceDataError: 'Проблема з обробкою данних сенсора',
    logSendSensorDataError: 'Проблема з обробкою данних сенсора',
    logSendDeviceDataError: 'Проблема з обробкою данних сенсора',
    logGetDeviceInfoError: 'Проблема із синхронізацією пристрою',
    logSensorCalibrationMoreThan100Error:
      'Проблема із калібровкою під даний тип ґрунту.\nЗверніться до супорту',
    logDevOtaStarted: 'Оновлюється програмне забезпечення',
    logDevOtaJobError: 'Помилка встановлення останньої версії ПЗ',
    logDevOtaFilesystemError: 'Помилка встановлення оновлення.\nПроблема з файловою системою',
    logInternalBatCritical: 'Критично низький рівень заряду батареї',
    logDevGpsInitFail: 'Проблема визначення геолокації',
    logDevGpsGetDataTimeout: 'Проблема визначення геолокації',
    logDevRs485Fail: 'Проблема з RS485',
    logPeripheralDeviceError: 'Проблема підключення сенсору',
    irrigating: 'Зрошення',
    fieldAlerts: 'Польові сповіщення',
    fieldAlertsNotConfigured: `Польові сповіщення не налаштовано.`,
    pressAddAlerts: `Натисніть 'Додати сповіщення', щоб отримувати сповіщення`,
    addAlerts: 'Додати сповіщення',
    noFieldAlertsWeek: 'Все ок. Критичних подій за останній тиждень на полі немає',
    showAll: 'Показати всі',
    configure: 'Налаштувати',
    yourAlertsWillBeSentTo: 'Ваші сповіщення будуть надіслані на {to}',
    lowerThanExample: '{value}, {units}',
    biggerThanExample: '{value}, {units}',
    lowerThan: 'менше ніж',
    greaterThan: 'більше ніж',
    plusAddConfig: '+ Додати налаштування',
    close: 'Зачинити',
    parameter: 'Параметр',
  },
}
