// const getEnvVar = (key: string) => {
//   if (import.meta.env[key] === undefined) {
//     throw new Error(`Env variable ${key} is required`)
//   }
//   return import.meta.env[key] || ''
// }

export const enum APIUrlsEnum {
  baseUrl = `https://app.droppity.co`,
  api = `https://app.droppity.co/api`,
  apiAuthGoogle = 'https://app.droppity.co/rest-auth/google/',
  apiAuthApple = 'https://app.droppity.co/rest-auth/apple/webapp/',
  apiCompany = 'https://app.droppity.co/api/company',
  apiFields = 'https://app.droppity.co/api/fields',
}

// export const NODE_ENV = getEnvVar('VITE_NODE_ENV')

// export const isDevEnv = NODE_ENV === 'development'
// export const isProdEnv = NODE_ENV === 'production'
