import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from 'shared/api/base'

export interface User {
  id: string
  username: string
  email: string
  first_name: string
  last_name: string
  avatar_url: string
  social_accounts?: {
    apple?: boolean
    google?: boolean
  }
}

export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['user'],
  endpoints: (build) => ({
    getUser: build.query<User, void>({
      query: () => {
        return `/rest-auth/user/`
      },
      providesTags: ['user'],
    }),
  }),
})
