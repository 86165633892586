import React from 'react'
import { useAppSelector } from 'app/store/hooks'
import { IntlProvider } from 'react-intl'
import { LOCALES } from 'shared/lib/i18n/locales'
import { messages } from 'shared/lib/i18n/messages'

// eslint-disable-next-line react/display-name
export const withIntl = (component: () => React.ReactNode) => () => {
  const lang = useAppSelector((store) => store.langReducer.lang)
  return (
    <IntlProvider
      messages={messages[LOCALES[lang ?? 'en']]}
      locale={LOCALES[lang ?? 'en']}
      defaultLocale={LOCALES.en}
    >
      {component()}
    </IntlProvider>
  )
}
