import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  langReducer,
  unitsGroupReducer,
  sidebarShowReducer,
  isMobileReducer,
  isFieldsReducer,
  currPageReducer,
  activeCardReducer,
} from 'shared/reducers'
import {
  fieldsListAPI,
  fieldWeatherAPI,
  fieldInstanceAPI,
  fieldActivitiesAPI,
  userCompanyAPI,
  userAPI,
  notesAPI,
  devicesAPI,
  irrigationAPI,
  alertsAPI,
} from 'shared/api/reducers'

const rootReducer = combineReducers({
  langReducer,
  sidebarShowReducer,
  unitsGroupReducer,
  isMobileReducer,
  isFieldsReducer,
  currPageReducer,
  activeCardReducer,
  [fieldsListAPI.reducerPath]: fieldsListAPI.reducer,
  [fieldWeatherAPI.reducerPath]: fieldWeatherAPI.reducer,
  [fieldInstanceAPI.reducerPath]: fieldInstanceAPI.reducer,
  [fieldActivitiesAPI.reducerPath]: fieldActivitiesAPI.reducer,
  [userCompanyAPI.reducerPath]: userCompanyAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [notesAPI.reducerPath]: notesAPI.reducer,
  [devicesAPI.reducerPath]: devicesAPI.reducer,
  [irrigationAPI.reducerPath]: irrigationAPI.reducer,
  [alertsAPI.reducerPath]: alertsAPI.reducer,
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        fieldsListAPI.middleware,
        fieldWeatherAPI.middleware,
        fieldInstanceAPI.middleware,
        fieldActivitiesAPI.middleware,
        userCompanyAPI.middleware,
        userAPI.middleware,
        notesAPI.middleware,
        devicesAPI.middleware,
        irrigationAPI.middleware,
        alertsAPI.middleware,
      ),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
